<template>
  <div>
    <div class="e-breadcrumb">预约设置</div>
    <div class="order-table-wrapper">
      <div class="e-card">
        <div class="e-card-body" style="padding-top:35px">
          <div style="margin-bottom: 35px">
            <div class="handle-box">
              <span class="v-form-label">预约开始时间:</span>
              <el-time-select
                size="small"
                style="width: 100%;"
                v-model="startTime"
                value-format="HH:mm"
                :picker-options="{
                 start: '00:00',
                 step: '00:30',
                 end: '23:59'
                 }"
                placeholder="选择时间">
              </el-time-select>
              <span class="v-form-label">预约结束时间:</span>
              <el-time-select
                size="small"
                style="width: 100%;"
                v-model="endTime"
                value-format="HH:mm"
                :picker-options="{
                 start: '00:00',
                 step: '00:30',
                 end: '23:59'
                 }"
                placeholder="选择时间">
              </el-time-select>

            </div>
            <div style="margin-right: 37px">
              <el-row type="flex" justify="end">
                <el-button size="small" class="e-button" type="primary" @click="searchEvent">查询
                </el-button>
                <el-button size="small" class="e-button" type="success" @click="addEvent()">新增
                </el-button>
              </el-row>
            </div>
          </div>
          <div class="e-table-wrapper">
            <table cellpadding="0" cellspacing="0" border="0" width="100%" style="text-align: center">
              <thead>
              <tr>
                <th width="160" class="th-l">预约开始时间:</th>
                <th width="160" class="th-l">预约结束时间</th>
                <th width="230" class="th-l">自助预约需提前 / 天数</th>
                <th width="230" class="th-l">当天预约需提前 / 分钟</th>
                <th width="230" class="th-l">预约时长占用模式</th>
                <th width="130" class="th-l">预约策略</th>
                <th width="100" class="th-l">可预约天数</th>
                <th width="130" class="th-l">创建时间</th>
                <th width="200" class="th-l">操作</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(row,index) in list" :key="row.id" :class="{ on: index % 2 == 0, off: index % 2 != 0 }">
                <td class="td-l"><span>{{row.startTime}}</span></td>
                <td class="td-l">{{row.endTime}}</td>
                <td class="td-l">{{row.advanceDay}}</td>
                <td class="td-l">{{row.advanceMin}}</td>
                <td class="td-l">
                  <span v-if="row.occupancyMode==0">预约时长叠加</span>
                  <span v-if="row.occupancyMode==1">预约时长累计</span>
                </td>
                <td class="td-l">
                  <span v-if="row.reservationStrategy==0">半点预约，半小时可预约一客</span>
                  <span v-if="row.reservationStrategy==1">0整点预约，一小时可预约一客</span>
                </td>
                <td class="td-l">{{row.daysAvailable}}</td>
                <td class="td-l" style="width: 150px"><span>{{row.createTime}}</span></td>

                <td class="td-l" >
                  <el-button type="text" icon="el-icon-edit" @click="editEvent(index)">编辑</el-button>
                  <el-button type="text" icon="el-icon-delete" class="red" @click="removeEvent(index)">删除</el-button>
                </td>

              </tr>
              </tbody>
            </table>
            <div class="pagination">
              <el-pagination
                background
                @current-change="handleCurrentChange"
                :pager-count="7"
                layout="total, prev, pager, next,jumper"
                :total="pagination.count"
                :page-count="pagination.pages"
                :current-page="pagination.pageNumber"
              ></el-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>


    <!-- 删除提示框 -->
    <el-dialog title="提示" :visible.sync="delVisible" width="300px" center>
      <div class="del-dialog-cnt">删除不可恢复，是否确定删除？</div>
      <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="delVisible = false">取 消</el-button>
                <el-button size="small" type="primary" @click="delBbiAppointment">确 定</el-button>
            </span>
    </el-dialog>

    <!-- 设置部门 -->
    <el-dialog title="设置部门" :visible.sync="setVisible" width="500px" center>
      <div>
      </div>
      <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="setVisible = false">取 消</el-button>
                <!--<el-button size="small" type="primary" @click="setDepartment">确 定</el-button>-->
            </span>
    </el-dialog>

  </div>


</template>

<script>

  export default {
    data() {
      return {
        startTime: '',
        endTime: '',


        list: [],
        pagination: {
          count: 0,
          pageNumber: 0,
          pageSize: 0,
          pages: 1
        },

        setVisible: false,
        delVisible: false,
        editItem: {},

        days: [],
        mins: [],
      }
    },
    async created() {
      this.getAppointmentDaysList()
      this.getAppointmentMinsList()
      this.searchEvent();
    },
    methods: {
      async getAppointmentDaysList() {
        let res = await this.$get('/admin/getDictDetailList/APPOINTMENT_DAYS/' + Math.random());
        this.days = res;
      },
      async getAppointmentMinsList() {
        let res = await this.$get('/admin/getDictDetailList/APPOINTMENT_MINS/' + Math.random());
        this.mins = res;
      },

      handleCurrentChange(page) {
        this.requestSearch(page)
      },

      searchEvent() {
        this.requestSearch()
      },
      async requestSearch(page) {

        let currentPage = page || 1;
        let data = {
          startTime: this.startTime,
          endTime: this.endTime,
          isDel: 0,
          size: this.size == '' ? '10' : this.size,
          currentPage
        }

        let res = await this.$get("/admin/getBbiAppointmentForPage", data)
        let list = res.dataSet.list;
        this.list = list;
        this.pagination = {
          count: res.count,
          pageNumber: res.pageNumber,
          pageSize: res.pageSize,
          pages: res.pages
        }
      },


      addEvent() {
        this.$router.push("/appointments/saveAppointment")
      },

      editEvent(index) {
        this.$router.push("/appointments/updateAppointment?id=" + this.list[index].id)
      },


      async removeEvent(index) {
        this.editItem = this.list[index]
        this.delVisible = true;
      },

      //删除delBbiAppointment
      async delBbiAppointment() {
        let data = {
          id: this.editItem.id
        };

        let res = await this.$post("/admin/delete_bbiAppointment", data)
        if (res.code == 200) {
          this.searchEvent();
          this.$message({
            showClose: true,
            message: res.message,
            type: 'success'
          });
          this.delVisible = false;
        } else {
          this.$message({
            showClose: true,
            message: res.message,
            type: 'error'
          });
        }
      },

    },
  }

</script>

<style scoped>
  .th-l {
    text-align: center;
    padding-right: 10px;
    font-size: 14px;
  }

  .td-l {
    padding: 10px 12px;
    display: table-cell;
    text-align: center;
    vertical-align: middle;
  }

  .e-table-wrapper tbody .el-button {
    padding: 3px 0px;
  }
 
.off {
  background-color: #fff;
}
.on {
  background-color: #ececea;
}
</style>
